.slider {
	height: auto;
	padding: 55px 25px 5px 25px;
	background-color: #0793cb;

	.bx-wrapper {
		margin: 0 auto;

		.bx-viewport {
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
			border: none;
			left: 0;
			background: transparent;
		}
	}

	.bxslider li {
		background-color: #0793cb;
		text-align: center;
	}

	h1 {
		font-size: 36px;
		line-height: 40px;
		font-weight: 400;
		color: #ffffff;
		text-transform: none;
	}
	p {
		color: #ffffff;
		text-transform: uppercase;
		padding: 130px 0 25px 0;
	}

	.bx-wrapper .bx-pager.bx-default-pager a {
		background: transparent;
		border: 1px solid #ffffff;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
		background: #ffffff;
	}
	.bx-wrapper .bx-pager.bx-default-pager a.active {
		background: #ffffff;
	}

	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
		bottom: -20px;
	}

}