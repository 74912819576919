@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}


@mixin rotate($deg) {
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-ms-transform: rotate($deg);
	-o-transform: rotate($deg);
}

//USAGE
/* 
@include transition( color, 0.3s, ease-in-out );
*/


//All sides boder radius
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	border-radius: $radius;
	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

//USAGE
/* 
@include border-radius( 50% );
*/


// Single side border-radius
@mixin border-top-radius($radius) {
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;

	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;

	background-clip: padding-box;
}
@mixin border-right-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;

	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;

	background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;

	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;

	background-clip: padding-box;
}
@mixin border-left-radius($radius) {
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;

	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;

	background-clip: padding-box;
}

//USAGE
/* 
@include border-top-radius(10px);
@include border-right-radius(8px);
@include border-bottom-radius(10px);
@include border-left-radius (6px); 
*/

//Style Placeholders
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

//USAGE
/* 
@include placeholder {
    font-style:italic;
    color: white;
    font-weight:100;
} 
*/

//Icon Font
@mixin icon-font($icon) {
	content: $icon;

	font-family: "tlc-sagres" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin icon-font-family() {
	font-family: "tlc-sagres" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
//USAGE
/* 
@include icon-font( 'a' );
*/

//Icon Font
@mixin vAlign($align) {
	display: inline-block;
	vertical-align: $align;
	width: 0;
	height: 100%;
}

//USAGE
/* 
@include vAlign( middle );
*/


//Box Shadow
@mixin box-shadow($horizontal, $vertical, $blur, $spread, $color) {
	-webkit-box-shadow: $horizontal $vertical $blur $spread $color;
	   -moz-box-shadow: $horizontal $vertical $blur $spread $color;
	        box-shadow: $horizontal $vertical $blur $spread $color;
}

//USAGE
/*
@include box-shadow( 0, 8px, 6px, -6px, #eeeeee );
*/

//Prevent text Selection
@mixin no-selection{
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

//USAGE
/*
@include no-selection;
*/

//Icon Font
@mixin fontIcon( $icon ) {
  font-family: "tlc-recheio" !important;
  content: $icon;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}