main {
	em {
		font-weight: bold;
		text-decoration: underline;
	}

	section {
		padding: 30px 0;
	}

	section.ajudar {

		h1 {
			font-family: $titlefont-italic;
			color: #00ACF2;
		}
		iframe.youtube {
			width: 100%;
			height: 500px;
			margin: 20px 0;
			@media screen and (max-width: 640px) {
				height: 50vw;
			}
		}
		h2 {
			font-size: 20px;
			font-weight: 900;
			line-height: 26px;
			color: #514C4D;
			padding-top: 10px;
			padding-bottom: 26px;
			max-width: 780px;
			mark{
				background-color: #00ACF3;
				padding:3px 5px;
				color: white;
				font-weight: bold;
			}
			@media screen and (max-width: 640px) {
				font-size: 16px; line-height: 18px;
			}
			@media screen and (max-width: 340px) {
				padding-bottom: 20px;
			}
			u {
				font-weight: bold;
			}
		}

		p{
			margin-bottom: 10px;
		}

		p.icon-text {
			font-size: 24px;
			line-height: 34px;
			padding-bottom: 25px;
			max-width: 370px;
			display: inline-block;

			@media screen and (max-width: 640px) {
				font-size: 16px; line-height: 18px;
				width: 70%;
			}
		}
		button.steps{
			background-color: #00acf5;
			border: none;
			font-size: 20px;
			color: white;
			position: relative;
			top: -10px;
    		left: 20px;
			border-radius: 12px;

			@media screen and (max-width: 820px) {
				margin: 0 auto;
			    display: block;
			    margin-top: 22px;
			    position: initial;
			}

			p{
				font-size: 12px;
				color: white;
				margin: 0;
				padding:18px;
				line-height: 15px;
				font-weight: bold;
			}
		}

		div.steps-popup{
			background-color: rgba(0,0,0,.6);
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1000;
			display: none;
			&.open{
				display: block;
			}
			.cross{
				cursor: pointer;
				position: absolute;
				top: 0;
				right: 0;
			}

			div.steps-popup-container{
				background: #FFF;
				position: fixed;
				width: 700px;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				padding: 60px 40px;
				max-width: 95%;
				border-radius: 2px;
				max-height: 90vh;
				overflow-y: auto;
				div.popup-close{
					position: absolute;
					right: 0;
					top:0;
					cursor: pointer;
				}
				img{
					max-width: 100%;
				}
				li{
					display: none;
					&.active{
						display: block;
					}
				}
				li div{
					background-color: #333;
					color: #FFF;
					padding: 10px;
				}
				span.prev,
				span.next{
					float: right;
					margin-top: -10px;
					line-height: 40px;
					padding: 0 10px;
					cursor: pointer;
				}

				@media (max-width: 768px) {
					padding: 60px 20px;
				}
			}
		}

		p.text-note{
			font-size: 14px;
			font-style: italic;
			margin-bottom: 35px;
		}

		.helpTable {
			@media screen and (max-width: 690px) {
				width: 100%;
			}
		}

		.note {
			padding: 0 20px 0 0;
			margin-top: -45px
		}
	}

	section.todos {
		background-color: #E0F3FE;

		@media screen and (max-width: 640px) {
			padding-bottom: 10px;
		}

		p {
			font-size: 20px;
			line-height: 28px;
			padding-bottom: 25px;

			@media screen and (max-width: 640px) {
				font-size: 16px; line-height: 18px;
			}
		}
	}

	section.comoFazer {
		background: url('../images/bg-comoFazer2.jpg') no-repeat center 33%;
		background-size: cover;

		h1 {
			font-family: $titlefont-italic;
			color: #DFF2FF;
			padding-bottom: 30px;

			@media screen and (max-width: 500px) {
				padding-top: 15px;
			}
		}
		h2 {
			font-family: $titlefont;
			font-size: 30px;
			line-height: 45px;
			color: #FFFFFF;
			padding-top: 350px;

			@media screen and (max-width: 810px) {
				font-size: 28px;
				line-height: 40px;
				padding-top: 200px;
			}
			@media screen and (max-width: 640px) {
				font-size: 23px; line-height: 35px;
			}
			@media screen and (max-width: 440px) {
				font-size: 19px; line-height: 30px;
			}

			i {
				background-color: #00ACF5;
				font-style: normal;
			}
		}
		h3 {
			font-size: 20px;
			line-height: 25px;
			font-weight: 900;
			color: #FFFFFF;
			padding: 5px 0;

			@media screen and (max-width: 640px) {
				font-size: 16px; line-height: 18px;
			}
		}
		hr{
			margin: 0 0 10px;
		}
	}

	section.entidade {
		padding-bottom: 3px;

		img {
			margin-top: 20px;
			max-width: 100%;

			@media screen and (max-width: 900px) {
				width: 100%;
			}
			@media screen and (max-width: 380px) {
				margin-top: 0px;
				padding-bottom: 20px;
			}
		}

		article.block-container{
			overflow: hidden;
		}

		a.rules{
			text-align: center;
			margin: 0 auto;
			font-family: "CreteRound-Italic", Arial, Helvetica, sans-serif;
			color: #514c4d;
			font-size: 40px;
			line-height: 44px;
			font-weight: 400;
			display: block;
			margin-top: 30px;
		}

		p.rules-title{
			text-align: center;
			margin: 0 auto;
			font-family: "CreteRound-Italic", Arial, Helvetica, sans-serif;
			color: #514c4d;
			font-size: 30px;
			line-height: 44px;
			font-weight: 400;
			display: block;
			margin-top: 30px;
		}


		.left {
			width: 49%;
			float: left;
			@media screen and (max-width: 800px) {
				width: 100%;
			}
		}
		.right {
			width: 49%;
			float: right;

			@media screen and (max-width: 800px) {
				width: 100%;
			}
		}

		h2 {
			font-family: $titlefont;
			font-size: 20px;
			line-height: 25px;
			color: #00ACF4;
			padding: 0px 0 10px 0;
			width: 370px;

			@media screen and (max-width: 800px) {
				width: 100%;
			}
		}

		h2.title-contribuinte {
			font-family: $titlefont;
			font-size: 20px;
			line-height: 25px;
			color: #00ACF4;
			padding: 0px 0 10px 0;
			width: 370px;

			@media screen and (max-width: 800px) {
				width: 100%;
			}
		}

		ul {
			font-size: 0px;
			padding-top: 52px;

			@media screen and (max-width: 380px) {
				padding-top: 10px;
			}

			.bg-li {
				background-color: #E0F3FE;
				padding: 10px 10px 10px 15px;
			}

			li {
				width: 100%;
				display: inline-block;
				vertical-align: top;
				text-align: left;
				color: #FFFFFF;
				padding-bottom: 30px;

				@media screen and (max-width: 800px) {
					width: 100%;
				}
				p {
					width: 370px;
					padding-bottom: 7px;

					@media screen and (max-width: 800px) {
						width: 100%;
					}
				}
				p.finance-text{
					font-style: italic;
					a{
						text-decoration: underline;
					}
				}
				.bg-p {
					background-color: #E0F3FE;
					padding: 10px 10px 10px 15px;
				}
			}
		}
		ul.rules-contribuinte{
			padding-top: 10px;
		}
	}

	section.duvidas {
		background-color: #E0F3FE;
		text-align: center;

		h1 {
			font-family: $titlefont-italic;
			font-size: 26px;
			line-height: 28px;
			font-weight: 400;
			color: #00ACF2;
		}
		p {
			max-width: 720px;
			margin: 0 auto;
			padding-top: 18px;

			a {
				color: #00ACF2;
			}
			a:hover {
				text-decoration: underline;
			}
		}
		.lineInfo {
			font-size: 12px;
			color: #514C4C;
			padding-top: 12px;
		}
	}

	section.donativo {
		background: url('../images/bg-donativo.jpg') no-repeat top center;
		padding-bottom: 0px;
		h1 {
			font-family: $titlefont-italic;
			color: #DFF2FF;
			padding-bottom: 30px;
		}
		h2 {
			font-family: $titlefont;
			padding: 320px 0 0 0;
			font-size: 28px;
			line-height: 30px;

			@media screen and (max-width: 980px) {
				padding-top: 270px;
			}

			@media screen and (max-width: 670px) {
				font-size: 24px;
				line-height: 26px;
				padding-top: 250px;
			}
			@media screen and (max-width: 400px) {
				padding: 180px 0 0 0;
			}
		}
		hr {
			margin: 0;
			@media screen and (max-width: 670px) {
				margin-top: 10px;
				margin-bottom: 5px;
			}
		}
		h3 {
			font-size: 20px;
			line-height: 22px;
			font-weight: 900;
			color: #FFFFFF;
			padding: 15px 0;

			@media screen and (max-width: 670px) {
				font-size: 16px; line-height: 18px;
			}
			@media screen and (max-width: 400px) {
				font-weight: 400;
				font-size: 14px; line-height: 16px;
			}
		}
	}

	section.diferencaTitle {
		background-color: #E0F3FE;
		text-align: center;

		h1 {
			font-family: $titlefont-italic;
			padding: 20px 0 15px 0;
		}
	}
	section.diferenca {
		padding-bottom: 0;

		ul {
			font-size: 0px;
			padding-top: 52px;

			@media screen and (max-width: 760px) {
				width: 100%;
				padding-top: 32px;
			}

			li {
				width: 50%;
				display: inline-block;
				vertical-align: top;
				text-align: left;
				color: #FFFFFF;
				padding-bottom: 30px;

				@media screen and (max-width: 760px) {
					width: 100%;
				}

				.iconDif {
					padding: 0 20px 0 0;
					margin-top: -50px;

					@media screen and (max-width: 760px) {
						margin-top: -15px;
					}
				}

				.iconAgua {
					@media screen and (max-width: 660px) {
						margin-top: -40px;
					}
				}

				h2 {
					font-family: $titlefont-italic;
					font-size: 40px;
					line-height: 42px;
					color: #00ACF2;
					padding: 0px 0 27px 0;
					width: 370px;
					display: inline-block;

					@media screen and (max-width: 940px) {
						font-size: 34px; line-height: 36px;
						width: 60%;
					}
					@media screen and (max-width: 760px) {
						width: 70%;
					}
				}
				h3 {
					width: 400px;
					font-size: 26px;
					line-height: 28px;
					color: #00ACF2;
					padding: 68px 0 34px 50px;
					@media screen and (max-width: 840px) {
						width: 100%;
					}
				}
				p {
					width: 400px;
					padding: 0 0 28px 0;

					@media screen and (max-width: 840px) {
						width: 100%;
						padding-right: 10px;
					}

					b {
						color: #00ACF2;
					}
				}

			}
			.bgFull {
				background-color: #DFF3FF;

				h3 {
					@media screen and (max-width: 840px) {
						padding: 40px 10px 34px 30px;
					}
				}

				p {
					padding: 0 0 52px 50px;

					@media screen and (max-width: 840px) {
						padding: 0 10px 52px 30px;
					}
				}
			}
		}
	}

	div#mobile-header{
		background-color: #00aded;;
		margin-top: -30px;
		text-align: center;
		padding: 55px 0 30px;
		position: relative;
		h1{
			font-family: "CreteRound-Italic", Arial, Helvetica, sans-serif;
			color: #FFF;
			margin: 0;
			padding: 20px 0 15px;
			width: inherit;
			line-height: 60px;
			font-size: 40px;
			span.cross{
				height: 54px;
				width: 54px;
				background-image: url(/images/ico-cross.png);
				vertical-align: middle;
				margin-left: 10px;
				display: inline-block;
			}
		}

		p{
			vertical-align: baseline;
			font-family: "CreteRound-Regular", Arial, Helvetica, sans-serif;
			font-size: 22px;
			line-height: 26px;
			padding: 15px;
			color: #FFF;
			display: inline-block;

			span{
				font-weight: 900;
				color: #FFFFFF;
				background-color: #00ACF5;
				padding: 5px;
				display: inline-block;
				font-size: 22px;
			}
		}

		div.social-nav{
			position: absolute;
			top: 5px;
			left: 0;
			right: 0;

			ul{
				padding: 15px 15px;

				li{
					display: inline-block;
					padding: 0 10px;
				}
			}
		}

		@media screen and (min-width: 821px) {
			display: none;
		}
	}
}
