header {
	margin-top: -10px;
	padding-bottom: 20px;
	background: url('../images/banner-kids.jpg') no-repeat top center;
	background-size: cover;
	height: 58vw;

	@media screen and (max-width: 600px) {
		background-position: 60%;
		margin-top: -20px;
    }

	@media screen and (max-width: 820px) {
		margin-top: 70px;
		margin-bottom: 30px;
		.wrapper{
			padding: 0;
		}
		.navWrapper{
			display: none;
		}
    }
    @media screen and (max-width: 540px) {
        background: url('../images/banner-kids-mobile.jpg') no-repeat top center;
        height: 480px;
        margin-top: 68px;
    }

	.topNavWrapper{
		padding-bottom: 30px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
        z-index: 10;


		h3 {
			margin-top: 15px;

			@media screen and (max-width: 500px) {
				margin-top: 0;
				width: 80%;
			}
			@media screen and (max-width: 360px) {
				width: 70%;
			}
		}
    }
    .topWrapper {
        height: 70px;
        @media screen and (max-width: 820px) {
            height: auto;
        }
    }

	.headerHashtag{
		float: left;
		font-family: $mainfont;
		font-size: 18px;
		line-height: 20px;
		color: #d5eaf6;
	}

	.title-logo {
		width: 250px;
		padding: 16px 0;
		text-align: center;
        z-index: 2;
        position: fixed;
		@media screen and (max-width: 820px) {
			width: inherit;
            background-color: $secondarycolor;
            position: relative;
            img {
                width: 250px;
            }
		}
	}

	.formWrapper {
		width: 940px;
		height: 440px;
		position: absolute;
		top: 130px;
		left: 0;
		right: 0;
		z-index: 10;
		background-color: transparent;
		margin: 0 auto;
		display: none;

		@media screen and (max-width: 940px) {
			width: 100%;
		}
		@media screen and (max-width: 500px) {
			top: 120px;
			z-index: 50;
		}

		.formMission {
			width: 880px;
			height: 440px;
			padding: 40px 50px 0 40px;
			background-color: rgba(17, 152, 206, 0.95);

			@media screen and (max-width: 940px) {
				width: 85%;
			}
			@media screen and (max-width: 500px) {
				width: 100%;
				height: 458px;
				padding: 20px 20px 0 20px;
			}

			.formHeader {
				h1 {
					width: 95%;
					padding: 0;
					padding-bottom: 25px;
					font-size: 40px;
					color: #DFF2FF;
					display: inline-block;

					@media screen and (max-width: 940px) {
						font-size: 25px;
						line-height: 30px;
					}
					@media screen and (max-width: 700px) {
						width: 85%;
						font-size: 20px;
						line-height: 25px;
					}
					@media screen and (max-width: 500px) {
						width: 90%;
						font-size: 17px;
						padding-bottom: 10px;
					}
				}
				img {
					float: right;
					padding: 20px 0 0 0;

					@media screen and (max-width: 940px) {
						padding: 0;
					}
				}
			}

			.form-group {
				width: 47%;
				display: inline-block;
				margin-bottom: 20px;

				@media screen and (max-width: 500px) {
					width: 100%;
					margin-bottom: 10px;
				}
				@media screen and (max-width: 380px) {
					margin-bottom: 5px;
				}

				.form-unicef {
					height: 40px;
					font-size: 18px;
					border: 0;
					border-radius: 0;
					color: #00ABF8;

					@media screen and (max-width: 700px) {
						height: 30px;
						font-size: 16px;
					}
				}

				.error {
					font-size: 14px;
					line-height: 16px;
					padding: 2px;
					color: #D90000;

					@media screen and (max-width: 700px) {
						font-size: 12px;
						line-height: 14px;
					}
					@media screen and (max-width: 380px) {
						font-size: 10px;
						line-height: 14px;
					}
				}
				.success {
					font-size: 14px;
					padding: 2px;
					color: #008C00;

					@media screen and (max-width: 700px) {
						font-size: 12px;
						line-height: 14px;
					}
					@media screen and (max-width: 380px) {
						font-size: 10px;
						line-height: 14px;
					}
				}
			}
			.form-rig {
				float: right;
			}
			.checkbox {
				margin-top: 0;

				@media screen and (max-width: 380px) {
					margin-bottom: 0;
				}

				label {
					font-size: 12px;
					line-height: 20px;
					color: #FFFFFF;

					@media screen and (max-width: 640px) {
						font-size: 10px;
						line-height: 17px;
					}
					@media screen and (max-width: 367px) {
						line-height: 12px;
					}
				}
				p {
					font-size: 12px;
					line-height: 12px;
					color: #FFFFFF;
					padding-top: 10px;

					@media screen and (max-width: 640px) {
						font-size: 10px;
						line-height: 10px;
						padding-top: 5px;
					}
					@media screen and (max-width: 500px) {
						padding-top: 0;
					}
				}
			}
			.btnGroup {
				text-align: center;
				padding-top: 10px;

				.btn-unicef {
					font-family: $mainfont;
					font-size: 24px;
					font-weight: 900;
					color: #00ABF8;
					text-transform: uppercase;
					padding: 7px 50px;
					background-color: #FFFFFF;
					border-radius: 0;
					box-shadow: 3px 4px 0px 0px #036B99;

					@media screen and (max-width: 500px) {
						font-size: 18px;
						padding: 7px 40px;
					}
				}
			}
		}

		.formTab {
			width: 60px;
			height: 68px;
			position: absolute;
			right: 0;
			bottom: 0;
			background-color: rgba(17, 152, 206, 0.95);

			@media screen and (max-width: 940px) {
				display: none;
			}
		}
	}

	.mainNav{
		overflow: hidden;
		margin-top: -75px;
		position: fixed;
		left: 0;
		right: 0;
        height: 80px;
        background-color: #00aded;

		@media screen and (max-width: 940px) {
			left: -15%;
		}
		@media screen and (max-width: 820px) {
			display: none;
		}

		ul{
			width: 940px;
			font-size: 0;
			margin: 0 auto;
			padding: 28px 0px;
			text-align: right;
			li {
				display: inline-block;
				vertical-align: top;
				font-size: 16px;
				line-height: 20px;

				a{
					padding: 0 10px;
					font-size: 13px;
					letter-spacing: 0.5px;
					font-weight: 900;
					color: #ffffff;

					@include transition( all, 0.3s, ease-in-out );

					&:visited{
						color: #ffffff;
					}

					&:hover, &.active {
						line-height: 20px;
						color: #d5eaf6;
					}

					&.active {
						font-weight: 600;
					}
				}
			}
		}
	}

	.darkHeader {
		background-color: rgba( #00aded, 0.6);
		-webkit-transition: background 2s;
		transition: background 2s;
	}

	.socialNav{
		float: right;
		margin-top: 70px;

		ul{
			display: block;
			width: 60px;
			padding-left: 7px;
			font-size: 0;

			hr {
				margin-top: 7px;

				@media screen and (max-width: 640px) {
					display: none;
				}
			}

			li{
				display: inline-block;
				vertical-align: top;
				width: 20px;
				height: 50px;
				margin: 0 10px;
				font-size: 20px;
				position: relative;
  				z-index: 50;

  				@media screen and (max-width: 500px) {
  					float: right;
  				}
				a{
					color: #ffffff;

					@include transition( all, 0.3s, ease-in-out );

					&:visited{
						color: #ffffff;
					}

					&:hover, &.active {
						line-height: 20px;
						color: #d5eaf6;
					}

					&.active {
						font-weight: 900;
					}
				}
				.share-icon{
					width: 34px;
				}
			}
		}
	}

	h1 {
		width: 470px;
		font-family: $titlefont-italic;
		color: #FFFFFF;
		font-size: 36px;
		line-height: 50px;
		padding: 110px 0 45px 0;
		display: inline-block;
		i {
			background-color: #00ACF5;
			font-style: normal;
		}

		@media screen and (max-width: 640px) {
			width: 80%;
			font-size: 28px;
			line-height: 40px;
			padding: 110px 0 25px 0;
		}
		@media screen and (max-width: 360px) {
			width: 75%;
			font-size: 24px;
			line-height: 35px;
			padding-top: 90px;
		}
	}
	.cross {
		margin-left: -130px;
		margin-top: -20px;

		@media screen and (max-width: 640px) {
			margin-left: -50%;
			margin-top: -8px;
			width: 8%;
		}
		@media screen and (max-width: 540px) {
			margin-left: -20%;
			margin-top: -8px;
			width: 10%;
		}
		@media screen and (max-width: 460px) {
			margin-left: -15%;
		}
		@media screen and (max-width: 435px) {
			margin-left: -65%;
			margin-top: -5px;
			width: 12%;
		}
		@media screen and (max-width: 407px) {
			margin-left: -30%;
		}
		@media screen and (max-width: 360px) {
			margin-left: -30%;
		}
	}
	h2 {
		width: 510px;
		font-family: $titlefont;
		font-size: 22px;
		line-height: 26px;
		max-height: 52px;

		@media screen and (max-width: 640px) {
			width: 80%;
			font-size: 18px;
			line-height: 20px;
			max-height: none;
		}
		@media screen and (max-width: 380px) {
			font-size: 16px;
			line-height: 18px;
		}
	}
	hr {
		@media screen and (max-width: 640px) {
			width: 78%;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		@media screen and (max-width: 360px) {
			width: 75%;
		}
	}
	h3 {
		width: 620px;
		font-size: 20px;
		line-height: 20px;
		color: #FFFFFF;
		display: inline-block;

		@media screen and (max-width: 720px) {
			font-size: 16px;
			line-height: 20px;
			margin-top: 0;
			width: 60%;
		}
		@media screen and (max-width: 360px) {
			width: 100%;
			margin-bottom: 10px;
		}
	}
	span.nif {
		font-size: 30px;
		line-height: 30px;
		font-weight: 900;
		color: #FFFFFF;
		background-color: #00ACF5;
		padding: 5px;
		display: inline-block;

		@media screen and (max-width: 720px) {
			font-size: 20px;
			line-height: 25px;
		}
		@media screen and (max-width: 500px) {
			display: inline-block;
		}
	}

	.headerTitles{
		height: 600px;
		position: relative;

		.wrapper{
			height: 100%;
			position: relative;
		}

		.titleHighlight{

			display: none;
			padding: 15px;
			position: absolute;
			top: 230px;
			left: 0;
			font-family: $mainfont;
			font-size: 48px;

			@include border-right-radius( 70px );

			background-color: $maincolor;
		}

		h1{
			position: absolute;
			bottom: 30px;
			left: 0;

			font-family: $mainfont;
			font-size: 60px;
			line-height: 90px;

			color: #ffffff;
			span{
				color: $secondarycolor;
				font-size: 80px;
				line-height: 90px;
			}
		}
	}
}
