.counterWrapper{
	h2{
		float: left;
		font-family: $mainfont;
		font-size: 40px;
		color: #ffffff;
	}
	.counterStats{
		padding-left: 80px;
		position: relative;
		overflow: hidden;

			@media screen and (max-width: 800px) {
				padding-left: 70px;
				width: 100%;
				padding-top: 20px;
			}

		&:before{
			position: absolute;
			top: 20px;
			left: 15px;
			font-size: 50px;
			color: #ffffff;

			@media screen and (max-width: 500px) {
				top: 22px;
				font-size: 30px;
			}
		}

		.countValue{
			height: 80px;
			line-height: 80px;

			font-family: $mainfont;
			font-size: 50px;
			text-align: center;

			@include border-radius( 10px );

			color: #000000;
			background-color: #ffffff;

			@media screen and (max-width: 500px) {
				height: 40px;
				line-height: 40px;
				font-size: 20px;
			}
		}

		p{
			margin-top: 30px;
			font-size: 30px;
			line-height: 40px;
			color: #ffffff;

			@media screen and (max-width: 500px) {
				margin-top: 10px;
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}