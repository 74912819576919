html {
	background-color: #ebebeb;
}
body {
	display: block;
	width: auto;
	height: auto;
	margin: 0 auto;
	font-family: $mainfont, Verdana, Helvetica, sans-serif;
	color: #514C4C;
	outline: none;
	background: #FFFFFF;
	// min-width: 940px;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-family: $titlefont, Verdana, Helvetica, sans-serif; font-size: 40px; line-height: 44px; color: #00ACF3; font-weight: 400; display: block;

	@media screen and (max-width: 640px) {
		font-size: 22px;
		line-height: 24px;
	}
}

h2 { font-size: 61px; line-height: 62px; color: #ffffff;

	@media screen and (max-width: 640px) {
		font-size: 16px; line-height: 18px;
	}
}
h3 { font-size: 18px; line-height: 18px; color: #5c6e84; font-weight: 600;}

p {
	font-size: 16px; line-height: 20px; font-weight: 400; color: #514C4C;
}
ul { font-size: 14px; font-weight: normal; color: #68686a; }

b { font-weight: 900; }
strong { font-weight: 900; }

span {
	font-size: 12px;
	&.phone,
	&.mail,
	&.nif{
		font-size: inherit;
	}
}

input, select, span { line-height: 30px; }
label {
	display: inline-block;
	margin: 0;
	clear: left;
}

hr {
	border: 10px;
	padding: 5px;
	border-bottom: 2px dotted #ADA7A2;
	background: transparent;
}

.wrapper {
	width: 940px;
	margin: 0 auto;

	@media screen and (max-width: 940px) {
		margin: 0;
		padding: 0 20px;
		width: 100%;
	}
}
.clear { clear: both; }

.bg-foto {
	background: url('../images/bg-header.jpg') center center;
	height: 200px;
}

.underline {
	display: inline-block;
	padding-bottom: 10px;
	border-bottom: 7px solid #5c6e84;

	@media screen and (max-width: 500px) {
		padding-bottom: 5px;
		border-bottom: 3px solid #5c6e84;
	}
}
.strong-line {
	font-weight: 600;
}
.no-pad {
	padding: 0;
}

::-webkit-input-placeholder { /* WebKit browsers */
	color:#00ABF8!important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color:#00ABF8!important;
	opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color:#00ABF8!important;
	opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color:#00ABF8!important;
}

/* Default page blocks */
.blockSection{
	padding: 50px 0;

	@media screen and (max-width: 500px) {
		padding: 15px 0;
	}
}

/* Default more link */
.moreLink{
	display: inline-block;
	margin: 20px 0;
	font-size: 30px;

	color: $secondarycolor;

	@include transition( all, 0.3s, ease-in-out );

	&:visited{
		color: $secondarycolor;
	}

	&:hover{
		color: $maincolor;
	}
}

/* All header titles */
.headerTitles h1 {
	text-transform: none;
	font-weight: 400;
}

/* Scroll to Top link */
.scrollTop{
	display: inline-block;
	width: 48px;
	height: 48px;

	&:hover{
		&:before{
			color: darken(#d5eaf6, 10%);
		}
	}

	&:before{
		font-size: 48px;
		color: #d5eaf6;

		@media screen and (max-width: 500px) {
			font-size: 38px;
		}

		@include transition( color, 0.3s, linear);
	}
}
div.rules-button{
	background-color: $lightBlue;
	border-radius: 10px 0 0 10px;
	position: fixed;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);
	padding: 23px;
	cursor: pointer;
	z-index: 900;
	font-size: 13px;
	letter-spacing: 0.5px;
	font-weight: 900;
	color: #ffffff;

	span{
		font-family: "CreteRound-Regular", Arial, Helvetica, sans-serif;
		display: block;
		text-align: center;
		//font-weight: bold;
		font-size: 20px;
		line-height: 22px;
		padding: 0;

		&:last-child{
			margin-top: 1px;
		}
	}

	@media screen and (max-width: 820px) {
		left: 0;
		border-radius: 0;
		bottom: 0;
		top: inherit;
		transform: inherit;
		padding: 15px;
		text-align: center;
		border-top: 2px solid $secondarycolor;

		span{
			display: inline-block;
		}
	}
}

div.popup{
	background-color: rgba(0,0,0,.6);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	display: none;

	div.rules{
		background: #FFF;
		position: fixed;
		width: 700px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		padding: 60px 40px;
		max-width: 95%;
		border-radius: 2px;
		max-height: 90vh;
		overflow-y: auto;
		div.popup-close{
			position: absolute;
			right: 0;
			top:0;
			cursor: pointer;
		}
		h1{
			font-family: $titlefont-italic;
			color: $secondarycolor;
			padding-bottom: 10px;
		}
		p{
			font-size: 16px;
			line-height: 20px;
			color: $maincolor;
			margin-top: 16px;
		}

		@media (max-width: 768px) {
			padding: 60px 20px;
		}
	}
}

div.open{
	display: block;
}