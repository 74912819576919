//Main Font
@font-face {
    font-family: 'Arvo-Bold';
    src: url('../fonts/Arvo-Bold.eot');
    src: url('../fonts/Arvo-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Arvo-Bold.woff') format('woff'),
         url('../fonts/Arvo-Bold.ttf') format('truetype'),
         url('../fonts/Arvo-Bold.svg#Arvo-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Regular';
    src: url('../fonts/Lato-Regular.eot');
    src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lato-Regular.woff') format('woff'),
         url('../fonts/Lato-Regular.ttf') format('truetype'),
         url('../fonts/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Italic';
    src: url('../fonts/Lato-Italic.eot');
    src: url('../fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lato-Italic.woff') format('woff'),
         url('../fonts/Lato-Italic.ttf') format('truetype'),
         url('../fonts/Lato-Italic.svg#Lato-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CreteRound-Regular';
    src: url('../fonts/CreteRound-Regular.eot');
    src: url('../fonts/CreteRound-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/CreteRound-Regular.woff') format('woff'),
         url('../fonts/CreteRound-Regular.ttf') format('truetype'),
         url('../fonts/CreteRound-Regular.svg#CreteRound-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CreteRound-Italic';
    src: url('../fonts/CreteRound-Italic.eot');
    src: url('../fonts/CreteRound-Italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/CreteRound-Italic.woff') format('woff'),
         url('../fonts/CreteRound-Italic.ttf') format('truetype'),
         url('../fonts/CreteRound-Italic.svg#CreteRound-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

//Icon Font
@font-face {
  font-family: "novamente-tudo-ao-contrario";
  src:url("../fonts/novamente-tudo-ao-contrario.eot");
  src:url("../fonts/novamente-tudo-ao-contrario.eot?#iefix") format("embedded-opentype"),
    url("../fonts/novamente-tudo-ao-contrario.woff") format("woff"),
    url("../fonts/novamente-tudo-ao-contrario.ttf") format("truetype"),
    url("../fonts/novamente-tudo-ao-contrario.svg#novamente-tudo-ao-contrario") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "novamente-tudo-ao-contrario" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "novamente-tudo-ao-contrario" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "a";
}
.icon-mais:before {
  content: "b";
}
.icon-instagram:before {
  content: "c";
}
.icon-facebook:before {
  content: "d";
}
.icon-arrow-up:before {
  content: "e";
}
.icon-cogs:before {
  content: "f";
}
.icon-youtube:before {
  content: "g";
}

//Font Variables
$mainfont: 'Lato-Regular', Arial, Helvetica, sans-serif;
$mainfont-italic: 'Lato-Italic', Arial, Helvetica, sans-serif;
$titlefont: 'CreteRound-Regular', Arial, Helvetica, sans-serif;
$titlefont-italic: 'CreteRound-Italic', Arial, Helvetica, sans-serif;
