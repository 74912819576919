footer {
	padding: 35px 0 30px 0;
	background-color: #00ADF2;

	h1 {
		font-family: $mainfont;
		font-size: 20px;
		line-height: 22px;
		color: #FFFFFF;
	}
	h2 {
		font-family: $mainfont;
		font-size: 16px;
		line-height: 18px;
		color: #FFFFFF;
	}

	.left {
		width: 50%;
		display: inline-block;

		@media screen and (max-width: 940px) {
			width: 70%;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
		}
	
		ul {
			display: inline-block;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;
			padding-top: 30px;
			font-size: 0;

			li {
				float: left;
				height: 50px;
				font-size: 20px;
				line-height: 42px;
				color: #FFFFFF;
				margin: 0;
				padding-right: 20px;

				img {
					vertical-align: middle;
					width: 34px;
				}
				a {
					color: #FFFFFF;
				}
			}
			.follow-us {
				@media screen and (max-width: 400px) {
					width: 100%;
				}
			}
			.last {
				padding-left: 10px;
				@media screen and (max-width: 400px) {
					display: none;
				}
			}
		}
	}

	.right {
		width: 50%;
		float: right;
		height: 100px;

		@media screen and (max-width: 940px) {
			width: 30%;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
			float: none;
		}

		ul {
			text-align: right;
			display: inline-block;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: 0;

			@media screen and (max-width: 500px) {
				text-align: left;
			}

			li {
				height: 50px;
				font-size: 20px;
				line-height: 42px;
				color: #FFFFFF;
				margin: 0;
				padding-right: 20px;

				img {
					vertical-align: middle;
					@media screen and (max-width: 500px) {
						padding-top: 10px;
					}
				}
				a {
					color: #FFFFFF;
				}
			}

			.last-li {
				padding-top: 20px;
				img{
					width: 140px;
				}
			}
		}
	}
}
