.galleryWrapper{
	ul{
		margin: 0 -1%;
		font-size: 0;
		li{
			display: inline-block;
			vertical-align: top;

			width: 31.3333%;
			padding-bottom: 31.3333%;
			margin: 1%;

			position: relative;

			//background-color: #cccccc;

			@media screen and (max-width: 1100px) {
				padding-bottom: 40%;
			}
			@media screen and (max-width: 800px) {
				width: 48%;
				padding-bottom: 68%;
			}

			figure{
				display: block;
				height: 100%;
				width: 100%;

				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				overflow: hidden;

				&:hover{
					figcaption{
						bottom: 0;
					}
				}

				img{
					max-width: 100%;
					max-height: 100%;

					position: relative;
					z-index: 0;
				}

				figcaption{
					width: 100%;
					padding: 10px 0px;

					position: absolute;
					bottom: -100%;
					left: 0;
					right: 0;
					z-index: 1;

					text-align: center;

					background-color: #ffffff;

					@include transition( all, 0.5s, ease-in-out );

					.postName{
						font-size: 18px;
						text-transform: uppercase;
						color: $secondarycolor;
					}
					.postDate{
						font-size: 14px;
						color: $maincolor;
					}
				}
			}
		}
	}
}