*,h1,h2,h3,h4,h5,p,ul {
	margin: 0;
	padding: 0;
	outline: none;
	font-weight: normal;
}
ul { list-style-type: none; }

a, a:hover, a:visited, a:focus, a:active{ text-decoration: none; outline: 0;}
button::-moz-focus-inner { border: 0; outline: 0;}

img { border: 0; }
label { font-weight: normal; }

/* Bootstrap focus color fix */
	button:focus, input:focus, textarea:focus, select:focus { outline: none; }
	input, textarea, select { border: 0; }

/* IE8 PNG black borders fix */
	img {
		background: transparent!important;
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)"!important; /* IE8 */   
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)!important;   /* IE6 & 7 */      
		zoom: 1!important;
	}